<template>
  <div class="single-bridge-common">
    <c-title title="传感器列表" style="margin-bottom: 20px"></c-title>
    <div class="table-wrapper">
      <c-table
          :index="true"
          :data="pageData"
          :tableColumns="tableColumns"
          :stripe="true"
          :pagination="pageParams"
      >
        <template v-slot:custom="{ row }">
          <span class="opt-update" style="color:#F4BB4E" @click="gotoDetail(row)">历史运行状态查询</span>
        </template>
      </c-table>
    </div>
  </div>
</template>

<script>
  import Configs from "./config/sensor";
  export default {
    data() {
      let { tableColumns } = Configs(this);
      return {
        tableColumns,
        title:'',
        pageData:[],
        pageParams:{
          sensorTypeId:''
        }
      };

    },
    created() {
      this.pageParams.sensorTypeId = this.$route.query.sensorTypeId
      this.pageGet()
    },
    methods: {
      pageGet(){
        this.$http.get("/equipment/situation/getSensorList",{params:this.pageParams}).then(res => {
          if(res.success){
            if (res.data){
              this.pageData = res.data
            }
          }
        })
      },
      gotoDetail(data){
        this.$router.push({path:'/single/situation/history/detail',query:{tableName:data.tableName,sensorNo:data.sensorNo}})
      },
    },
  };
</script>

<style scoped>
  .form-flex{
    display: flex;
  }
  .form-item-btn{
    width: 100px;
    margin-left: 10px;
  }
  .opt-update:hover{
    cursor: pointer;
  }
</style>