export default function Configs(that) {
  const tableColumns = [
    {
      label: "传感器类型",
      prop: "sensorType",
      align: "center",
      show: true
    },
    {
      label: "传感器编号",
      prop: "sensorNo",
      align: "center",
      show: true
    },
    {
      label: "运行状态",
      prop: "state",
      align: "center",
      show: true
    },
    {
      label: "操作",
      name: 'custom',
      align: "center",
      show: true
    },
  ];
  return {
    tableColumns
  };
}
